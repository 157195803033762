// export const foodymanDocMenu = [
//   {
//     name: "Introduction",
//     routerPath: "/foodyman-single-documentation",
//   },
//   {
//     name: "Prerequisite",
//     dropDownItems: [
//       {
//         name: "Basic",
//         routerPath: "/foodyman-single-documentation/basic",
//       },
//       {
//         name: "Backend",
//         routerPath: "/foodyman-single-documentation/server",
//       },
//       {
//         name: "Frontend",
//         routerPath: "/foodyman-single-documentation/front",
//       },
//       {
//         name: "Recommendations",
//         routerPath: "/foodyman-single-documentation/recommendations",
//       },
//       {
//         name: "Mobile app",
//         routerPath: "/foodyman-single-documentation/mobile-app",
//       },
//     ],
//   },
//   {
//     name: "Local environment Setup",
//     dropDownItems: [
//       {
//         name: "Mobile app",
//         routerPath: "/foodyman-single-documentation/flutter-sdk",
//       },
//       {
//         name: "Frontend",
//         routerPath: "/foodyman-single-documentation/local-front",
//       },
//       {
//         name: "Backend",
//         routerPath: "/foodyman-single-documentation/local-server",
//       },
//     ],
//   },
//   {
//     name: "Backend",
//     dropDownItems: [
//       {
//         name: "Install on server",
//         routerPath: "/foodyman-single-documentation/mandatory-setup-backend",
//       },
//       {
//         name: "Troubleshooting",
//         routerPath: "/foodyman-single-documentation/troubleshooting-backend",
//       },
//     ],
//   },
//   {
//     name: "Admin Panel",
//     dropDownItems: [
//       {
//         name: "Install on server",
//         routerPath: "/foodyman-single-documentation/install-on-server",
//       },
//       {
//         name: "Mandatory setup",
//         routerPath: "/foodyman-single-documentation/mandatory-setup",
//       },
//       {
//         name: "Customization",
//         routerPath: "/foodyman-single-documentation/customization",
//       },
//       {
//         name: "Troubleshooting",
//         routerPath: "/foodyman-single-documentation/troubleshooting-admin",
//       },
//     ],
//   },
//   {
//     name: "Web App",
//     dropDownItems: [
//       {
//         name: "Mandatory setup",
//         routerPath: "/foodyman-single-documentation/mandatory-setup-web",
//       },
//       {
//         name: `Build code and setup on server`,
//         routerPath:
//           "/foodyman-single-documentation/build-code-and-setup-on-server",
//       },
//     ],
//   },

//   {
//     name: "Customer Apps",
//     dropDownItems: [
//       {
//         name: "Mandatory setup",
//         routerPath: "/foodyman-single-documentation/mandatory-setup-customer",
//       },
//       {
//         name: "Customization",
//         routerPath: "/foodyman-single-documentation/customization-customer",
//       },
//       {
//         name: "App build & release",
//         routerPath: "/foodyman-single-documentation/customer-app-build-release",
//       },
//     ],
//   },

//   {
//     name: "Vendor Apps",
//     dropDownItems: [
//       {
//         name: "Mandatory setup",
//         routerPath: "/foodyman-single-documentation/mandatory-setup-vendor",
//       },
//       {
//         name: "Customization",
//         routerPath: "/foodyman-single-documentation/customization-vendor",
//       },
//       {
//         name: "App build & release",
//         routerPath: "/foodyman-single-documentation/vendor-app-build-release",
//       },
//     ],
//   },
//   {
//     name: "Deliveryboy Apps",
//     dropDownItems: [
//       {
//         name: "Mandatory setup",
//         routerPath:
//           "/foodyman-single-documentation/mandatory-setup-deliveryboy",
//       },
//       {
//         name: "Customization",
//         routerPath: "/foodyman-single-documentation/customization-deliveryboy",
//       },
//       {
//         name: "App build & release",
//         routerPath:
//           "/foodyman-single-documentation/deliveryboy-app-build-release",
//       },
//     ],
//   },
//   {
//     name: "POS system",
//     dropDownItems: [
//       {
//         name: "Mandatory setup",
//         routerPath: "/foodyman-single-documentation/mandatory-setup-pos",
//       },
//       {
//         name: "Customization",
//         routerPath: "/foodyman-single-documentation/customization-pos",
//       },
//       {
//         name: "App build & release",
//         routerPath: "/foodyman-single-documentation/pos-app-build-release",
//       },
//     ],
//   },
//   {
//     name: "Firebase setup",
//     routerPath: "/foodyman-single-documentation/firebase",
//   },
//   {
//     name: "Update",
//     dropDownItems: [
//       {
//         name: "Admin Panel",
//         routerPath: "/foodyman-single-documentation/update-admin-panel",
//       },
//       {
//         name: "App & Web",
//         routerPath: "/foodyman-single-documentation/Update-app-web",
//       },
//     ],
//   },
//   {
//     name: "Support plan",
//     routerPath: "/foodyman-single-documentation/support-plan",
//   },
// ];

export const foodymanDocMenu = [
  {
    name: "Introduction",
    dropDownItems: [
      {
        name: "Introduction",
        routerPath: "/foodyman-single-documentation",
      },
      {
        name: "Server requirements",
        routerPath: "/foodyman-single-documentation/recommendations",
      },
    ],
  },
  {
    name: "Backend Api",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/server",
      },
      {
        name: "Payment installation",
        routerPath: "/foodyman-single-documentation/payment-installation",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/mandatory-setup-backend",
      },
      {
        name: "Troubleshooting",
        routerPath: "/foodyman-single-documentation/troubleshooting-backend",
      },
    ],
  },
  {
    name: "Admin Panel",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/admin",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/install-on-server",
      },
      {
        name: "Troubleshooting",
        routerPath: "/foodyman-single-documentation/troubleshooting-admin",
      },
    ],
  },
  {
    name: "Customer Website",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/front",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/mandatory-setup-web",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Customer App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/mobile-app",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/mandatory-setup-customer",
      },
      {
        name: "Customization",
        routerPath: "/foodyman-single-documentation/customization-customer",
      },
      {
        name: "App build & release",
        routerPath: "/foodyman-single-documentation/customer-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Seller and Waiter Apps",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/vendor-app",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/mandatory-setup-vendor",
      },
      {
        name: "Customization",
        routerPath: "/foodyman-single-documentation/customization-vendor",
      },
      {
        name: "App build & release",
        routerPath: "/foodyman-single-documentation/vendor-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Driver App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/driver-app",
      },
      {
        name: "Installation",
        routerPath:
          "/foodyman-single-documentation/mandatory-setup-deliveryboy",
      },
      {
        name: "Customization",
        routerPath: "/foodyman-single-documentation/customization-deliveryboy",
      },
      {
        name: "App build & release",
        routerPath:
          "/foodyman-single-documentation/deliveryboy-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Pos App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/pos-app",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/mandatory-setup-pos",
      },
      {
        name: "Customization",
        routerPath: "/foodyman-single-documentation/customization-pos",
      },
      {
        name: "App build & release",
        routerPath: "/foodyman-single-documentation/pos-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Kiosk App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/kiosk-app",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/mandatory-setup-kiosk",
      },
      {
        name: "Customization",
        routerPath: "/foodyman-single-documentation/customization-kiosk",
      },
      {
        name: "App build & release",
        routerPath: "/foodyman-single-documentation/kiosk-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "QR code App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/foodyman-single-documentation/qr-app",
      },
      {
        name: "Installation",
        routerPath: "/foodyman-single-documentation/install-qrcode",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Firebase setup",
    routerPath: "/foodyman-single-documentation/firebase",
  },
  {
    name: "Update",
    routerPath: "/foodyman-single-documentation/update",
  },
];
