import React from "react";
import { FcIdea } from "react-icons/fc";
import { Link } from "react-router-dom";
import { GiCampfire } from "react-icons/gi";
import NavigationBtns from "./Navigation-btns";

const MobileApp = ({ title = "Mobile app" }) => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">{title}</h1>
      <h3 className="introduction-contentTitle">Tools</h3>
      • For Mobile development: Visual Studio Code or Android studio , SDK and
      JDK with path setup in your IDE <br />
      <h3 className="introduction-contentTitle">Knowledge</h3>
      • For Mobile development: Dart , Flutter , basic Java and basic Swift
      knowledge <br />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        We would like to notify that the Envato price doesn’t include any kind
        of installation and app publishing support. We kindly ask you to follow
        the documentation step by step for installation, setup and other
        branding related changes. Please note that, we bear no responsibility
        for your mistake. You are fully in charge for any kind of customizations
        made by your own.
      </div>
      <div className="alert alert-success mt-4" role="alert">
        <div className="mb-2">
          <FcIdea size={22} /> <strong> TIP</strong>
        </div>
        You can download android studio via this link: <br />
        <a href="https://developer.android.com/studio?gclid=CjwKCAiAiKuOBhBQEiwAId_sK4X0PLQrES_2pG_S8nPflALgWSOCUEqRRAFpbS4AmR5mXmU6hIhvHxoCfBgQAvD_BwE&gclsrc=aw.ds">
          https://developer.android.com/studio?gclid=CjwKCAiAiKuOBhBQEiwAId_sK4X0PLQrES_2pG_S8nPflALgWSOCUEqRRAFpbS4AmR5mXmU6hIhvHxoCfBgQAvD_BwE&gclsrc=aw.ds
        </a>
      </div>
      • Flutter SDK setup (version 3.27.0{" "}
      <strong className="strong">Stable</strong>) <br />
      • Xcode (version 16.2) <br />
      • Java setup (version 23) <br />
      • Gradle (version 8.10) <br />
      • JDK with path setup (only for vs code) <br />
      • State management -> riverpod <br />
      <NavigationBtns />
    </div>
  );
};

export default MobileApp;
