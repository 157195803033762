import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const MandatorySetupWeb = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Build code and setup on server</h1>
      <h3 className="introduction-contentTitle"> Frontend website </h3>
      • Open /.env and change every single credential with your own
      <br />
      • If there is no .env file, please create the one in root folder and fill
      as the same as in the example
      <br />
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/foodyman-env.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/foodyman-env.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
            <a
              href="/assets/img/firebase-messaging.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/firebase-messaging.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      For building web data for deployment, you have to run commands:
      <br />
      • Install required package <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn</span>
          <span
            className={text === 3 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn", 3)}
          >
            {text === 3 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Build frontend using following command <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn build</span>
          <span
            className={text === 5 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn build", 5)}
          >
            {text === 5 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Run project with pm2 <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">pm2 start "yarn start"</span>
          <span
            className={text === 6 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn start", 6)}
          >
            {text === 6 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        You have to configure your server for front website. Front website runs
        in port 3000. open server configuration file and add{" "}
        <div className="introduction-code">
          <div>
            <span className="ps-0">
              ProxyPass / http://localhost:3000 <br /> ProxyPassReverse /
              http://localhost:3000
            </span>
            <span
              className={text === 6 ? "bg-success copy" : "copy"}
              onClick={() =>
                copyToClipBoard(
                  "ProxyPass / http://localhost:3000  ProxyPassReverse /http://localhost:3000",
                  7
                )
              }
            >
              {text === 7 ? "copied!" : "copy"}
            </span>
          </div>
        </div>{" "}
        in your domain configuration section. Rewrite mode should be enabled in
        your server. After adding, restart your server
      </div>
      <div className="iframe-wrapper mb-2">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Fd1TXISmw-o"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
      <NavigationBtns />
    </div>
  );
};

export default MandatorySetupWeb;
