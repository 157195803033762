import React, { useState } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const TroubleshootingAdmin = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };

  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Troubleshooting admin</h1>

      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Troubleshooting 1
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body has-white-color">
              <p>
                If you see above issue in your terminal, open package.json and
                remove GENERATE_SOURCEMAP=false. Then try to rebuild
              </p>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/food-doc/troubleshooting-1.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/food-doc/troubleshooting-1.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
              <div className="introduction-code">
                <div>
                  <span>GENERATE_SOURCEMAP=false</span>
                  <span
                    className={text === 1 ? "bg-success copy" : "copy"}
                    onClick={() =>
                      copyToClipBoard("GENERATE_SOURCEMAP=false", 1)
                    }
                  >
                    {text === 1 ? "copied!" : "copy"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              Troubleshooting 2
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample3"
          >
            <div className="accordion-body">
              If your website's design has style issur, do following steps
              <p>
                1. Before build, open package.json. <br />
                2. Replace "antd": "^4.20.6", with "antd": "4.20.6". <br />
                3. Then build and upload.
              </p>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/food-doc/antd.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/food-doc/antd.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              Troubleshooting 4
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse show"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample4"
          >
            <div className="accordion-body">
              If you meet this error, build app locally in your machine and
              upload to server. you meet this error, when you server is not
              strong to build the project. <br /> <br />
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    FATAL ERROR: Reached heap limit Allocation failed -
                    JavaScript heap out of memory 1: 00007FF61345168F
                    v8:internal CodeObjectRegistry::~CodeObjectRegistry+122159
                    2: 00007FF6133DB456 DSA_meth_get_flags+64118 3:
                    00007FF6133DC4D2 DSA_meth_ get_flags+68338 4:
                    00007FF613D13CB4 v8::Isolate::ReportExternalAllocation
                    LimitReached+ 116 5: 00007FF613CFE27D
                    v8::SharedArrayBuffer::Externalize+781 6: 00007FF613BA183C
                    v8:internal::Heap::EphemeronKeyWriteBarrierFromCode+1468 7:
                    00007FF613B9E954 v8::internal:: Heap::CollectGarbage+4244 8:
                    00007FF613B9C2D0 v8:internal::
                    Heap::AllocateExternalBackingStore+2000 9: 00007FF613BCOE56
                    v8:internal:: Factory::NewFillerObject+214 10:
                    00007FF6138F3565 v8.:internal::DateCache::Weekday+1797 11:
                    00007FF613DA1991
                    v8::internal::SetupisolateDelegate::SetupHeap+494417 12:
                    00007FF613D3689E
                    v8::internal::SetupisolateDelegate::SetupHeap+55902 13:
                    00000298B2BE89B2
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              Troubleshooting 5
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse show"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample4"
          >
            <div className="accordion-body">
              <h6 className="fw-normal">
                If you get this error, delete the env file or set
                REACT_APP_IS_DEMO=false
              </h6>{" "}
              <br /> <br />
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/project/troubleshooting5-error.png"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/project/troubleshooting5-error.png"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                    <a
                      href="/assets/img/project/troubleshooting5.png"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/project/troubleshooting5.png"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="true"
              aria-controls="collapseSix"
            >
              Troubleshooting 6
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse show"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample4"
          >
            <div className="accordion-body">
              <h6 className="fw-normal">
                If you meet this error, please, get recaptcha key using this
                tutorial.{" "}
                <strong>
                  <a
                    href="https://contactform7.com/recaptcha-v2/"
                    target="_blank"
                  >
                    link
                  </a>
                </strong>
              </h6>{" "}
              <br /> <br />
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/doc/recaptcha.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/doc/recaptcha.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="true"
              aria-controls="collapseSeven"
            >
              Troubleshooting 7
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse show"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample4"
          >
            <div className="accordion-body">
              <h6 className="fw-normal">
                We recommend to use yarn to manage packages. If you meet this
                error, use yarn or run "npm run —legacy-per-deps" command.{" "}
              </h6>{" "}
              <br /> <br />
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/troubleshooting-version.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/troubleshooting-version.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingEight">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="true"
              aria-controls="collapseEight"
            >
              Troubleshooting 8
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse show"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample4"
          >
            <div className="accordion-body">
              <h6 className="fw-normal">
                If you meet this error, re-upload <strong>.htaccess</strong>{" "}
                into the build folder.{" "}
              </h6>{" "}
              <br /> <br />
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/troubleshooting-htacces.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/troubleshooting-htacces.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigationBtns />
    </div>
  );
};

export default TroubleshootingAdmin;
