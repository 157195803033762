import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const InstallOnServer = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Install on server</h1>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Change title inside /public/index.html
      </div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Thus to install admin_panel, we need subdomains like admin.xyz.com and
        api.xyz.com.
      </div>
      <div className="alert alert-warning mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>Warning</strong>
        </div>
        You have to set SSL certificate for your admin website. Some functions
        doesn't work if your website doesn't have SSL certificate.
      </div>
      • Download the code from codecayon <br />• Extract the zip files
      <h3 className="introduction-contentTitle"> Admin Panel front </h3>
      • Open /public/index.html and change google map key with your google map
      key
      <br />
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/index-map-key.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/index-map-key.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      • Open /src/configs/app_global.js and put your URLs and keys
      <br />
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/adminConfig.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/adminConfig.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/configFirebase.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/configFirebase.png"
                alt="admin firebase"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      • Also select the main language
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/theme.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/theme.png"
                alt="theme config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p className="mb-0">
        • Create the <strong>build</strong> File
      </p>
      <p>
        In your application's root directory, run <strong>yarn </strong>
        to install the updated dependencies. Once this has finished, the next
        command you'll run is <strong>yarn build</strong>
      </p>
      <p>
        You'll notice this creates a new directory in your project called
        <strong> build</strong>. The build folder is essentially a
        super-compressed version of your program that has everything your
        browser needs to identify and run your app.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/build.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/build.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p className="mb-0">• Connect to cPanel</p>
      <p>Your cPanel manager should look something like this:</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/c-pannel.webp"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/c-pannel.webp"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p className="mb-0">
        • Add the Build File Contents to <strong>public_html</strong>
      </p>
      <p>
        Navigate to the build file in your app's root directory. Open it up and
        select all the contents <strong>inside the build file.</strong> If you
        upload the entire build file itself, the process will not work.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/build-file.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/build-file.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>
        Once you've copied all the contents inside the build file, upload them
        into <strong>public_html.</strong>
      </p>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p className="mb-0">
        • Create and Upload the <strong>.htaccess</strong> File
      </p>
      <p>
        In order for the routes to work in your React app, you need to add a
        <strong>.htaccess</strong> file. In the <strong>public_html</strong>
        folder, at the same level as the <strong>build</strong> file contents,
        add a new file and name it <strong>.htaccess.</strong>
      </p>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        After each project build process, you need to add the .htaccess file
        again.{" "}
      </div>
      <p>Edit the file and insert the following boilerplate information:</p>
      <div className="introduction-code">
        <p>
          {`<IfModule mod_rewrite.c>`}
          <br />
          {` RewriteEngine On`}
          <br />
          {` RewriteBase /`}
          <br />
          {`RewriteRule ^index\.html$ - [L]`}
          <br />
          {`RewriteCond %{REQUEST_FILENAME} !-f`}
          <br />
          {`RewriteCond %{REQUEST_FILENAME} !-d`}
          <br />
          {`RewriteCond %{REQUEST_FILENAME} !-l`}
          <br />
          {`RewriteRule . /index.html [L]`}
          <br />
          {`</IfModule>`}
        </p>
      </div>
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/food-doc/hta-2.webp"
            data-fancybox
            rel="nofollow"
          >
            <img
              src="/assets/img/food-doc/hta-2.webp"
              alt="admin config"
              loading="lazy"
              className="img-responsive-full"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <p>Save the file.</p>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        If you meet this error, please, get recaptcha key using this tutorial.{" "}
        <a href="https://contactform7.com/recaptcha-v2/" target="_blank">
          <strong>Link</strong>{" "}
        </a>
      </div>
      <div className="introduction-img-container mb-5">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/recaptcha.jpg"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/recaptcha.jpg"
                alt="admin install"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <iframe
        width="100%"
        height="420"
        src="https://www.youtube.com/embed/HMUKllwbY2s"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
      <NavigationBtns />
    </div>
  );
};

export default InstallOnServer;
