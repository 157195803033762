import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const MandatorySetupBackend = () => {
  return (
    <div
      className="introduction"
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Install on server backend</h1>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Thus to install admin_backend, we need subdomains like admin.xyz.com and
        api.xyz.com.
      </div>
      <div className="alert alert-danger mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>Warning</strong>
        </div>
        Don’t install the admin_backend in a sub directory (like:
        yourdomain.com/folder)
      </div>
      <p>
        1) Compress your Laravel project files into a ZIP file. If git,
        node_modules folders exist in your Laravel project, be sure not to add
        them in the zip file.
      </p>
      <p> 2) Login to your cPanel</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/c-panel.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/c-panel.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        3) Create a database & user. (Make sure to save the database name, user,
        and password into a text file in a safe place.)
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/create-db.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/create-db.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>4) Add new user</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/create-user.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/create-user.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>5) Add User to Database</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/add-user.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/add-user.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        6) When you connect subdomain to backend code, set public folder as root
        folder for this subdomains.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/domain.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/domain.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        7) Open your File Manager in cPanel and upload the ZIP file of your
        Laravel project into the public_html directory and extract the ZIP file.
        The ZIP file should be uploaded and extracted directly in the
        public_html folder and not inside any subfolder.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/backend-file.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/backend-file.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        8) Edit .env file in backend folder and add your database_name,
        database_user, database_user_password, IMG_HOST
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/backend_env.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/backend_env.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        • Now update your database details into the config file by opening the
        database.php file from the config folder and updating your database
        name, username and password into the database.php file. Now save the
        file. (Any sensitive credentials should not be uploaded in the env file
        in shared hosting.)
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/config.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/config.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        • To make your project secure and protect your .htaccess and .env files,
        open the .htaccess file from the public_html folder and write the
        following code to disable direct access and directory browsing:
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/config-2.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/config-2.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>• Open terminal. Go admin_backend folder.</p>
      <div className="introduction-code">
        <p>composer install</p>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "1.75rem" }}></div>
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/food-doc/composer.jpg"
            data-fancybox
            rel="nofollow"
          >
            <img
              src="/assets/img/food-doc/composer.jpg"
              alt="admin"
              loading="lazy"
              className="img-responsive-full"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        to install all php dependencies if you have not uploaded vendor folder.
        Then change your environmental variables in the .env file Generate a new
        application key
      </p>
      <div className="introduction-code">
        <p>php artisan key:generate</p>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "1.75rem" }}></div>
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/food-doc/generate.jpg"
            data-fancybox
            rel="nofollow"
          >
            <img
              src="/assets/img/food-doc/generate.jpg"
              alt="admin"
              loading="lazy"
              className="img-responsive-full"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Don’t forget to give a permission to write to storage, config and
        bootstrap folders.
      </div>
      <ul>
        <li>Create /config/init.php file with empty content</li>
        <div className="introduction-img-container">
          <SimpleReactLightbox>
            <SRLWrapper>
              <a
                href="/assets/img/doc/init-php.jpg"
                data-fancybox
                rel="nofollow"
              >
                <img
                  src="/assets/img/doc/init-php.jpg"
                  alt="images"
                  loading="lazy"
                  className="img-responsive-full"
                />
              </a>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <li>
          <strong>
            Change lisence keys in /config/credential.php with your’s
          </strong>
        </li>
      </ul>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/php_backend_food.jpg"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/php_backend_food.jpg"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/sertificate.png" data-fancybox rel="nofollow">
              <img
                src="/sertificate.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        <ul>
          <li>purchase_id = Item ID</li>
          <li>purchase_code = Item Purchase Code</li>
        </ul>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        Migrate and seed your database as per you need.
        <br /> 1. Migrate Tables: <strong>
          `php artisan migrate`
        </strong> and <br /> 2. Seed Database:{" "}
        <strong>`php artisan db:seed`</strong>
        <br />
        Set the correct folder permissions
      </p>
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/food-doc/migrate.jpg"
            data-fancybox
            rel="nofollow"
          >
            <img
              src="/assets/img/food-doc/migrate.jpg"
              alt="admin"
              loading="lazy"
              className="img-responsive-full"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "1.75rem" }}></div>
      <SimpleReactLightbox>
        <SRLWrapper>
          <a
            href="/assets/img/food-doc/migrate-2.jpg"
            data-fancybox
            rel="nofollow"
          >
            <img
              src="/assets/img/food-doc/migrate-2.jpg"
              alt="admin"
              loading="lazy"
              className="img-responsive-full"
            />
          </a>
        </SRLWrapper>
      </SimpleReactLightbox>
      <div className="introduction-code">
        <p>chmod -R 775 storage</p>
      </div>
      <p>Run storage link</p>
      <div className="introduction-code">
        <p className="mb-0">php artisan storage:link</p>
        <p>php artisan optimize:clear</p>
      </div>
      <p>Your app should work now.</p>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>Usage Commands</strong>
        </div>
        1. Migrate Tables: `php artisan migrate` <br /> 2. Seed Database: `php
        artisan db:seed` <br /> 3. Start Server: `php artisan serve` <br />
        4. Watch Server: `yarn watch` or `npm run watch`
      </div>
      <h5>Other Commands:</h5>
      <div className="introduction-code">
        <p className="mb-0">
          # Turn on maintenance mode <br /> php artisan down
        </p>
        <p className="mb-0">
          # Turn off maintenance mode <br /> php artisan up
        </p>
        <p className="mb-0">
          # Sync composer.local with composer.json <br /> composer update --lock
        </p>
        <p className="mb-0">
          # Create Model <br /> php artisan make:model Flight
        </p>
        <p className="mb-0">
          # Create Model & Migration <br />
          php artisan make:model Flight -m
        </p>
        <p className="mb-0">
          # Create Migration <br /> php artisan make:migration
          create_users_table --create=users
        </p>
        <p className="mb-0">
          # Update Existing Migration <br /> php artisan make:migration
          add_votes_to_users_table --table=users
        </p>
        <p className="mb-0">
          # Create Controller <br /> php artisan make:controller PhotoController
        </p>
        <p className="mb-0">
          # Composer dump <br /> composer dump-autoload
        </p>
        <p className="mb-0">
          # Clear caches <br /> php artisan cache:clear
        </p>
        <p className="mb-0">
          # Clear and cache routes <br /> php artisan route:clear <br /> php
          artisan route:cache
        </p>
        <p className="mb-0">
          # Clear and cache config <br /> php artisan config:clear <br /> php
          artisan config:cache
        </p>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>• How to setup foodyman project from cpanel - Backend</p>
      <iframe
        width="100%"
        height="420"
        src="https://www.youtube.com/embed/TKyjjO8yZY0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
      <NavigationBtns />
    </div>
  );
};

export default MandatorySetupBackend;
